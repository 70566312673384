/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import {OBJECT_TYPE} from "../constants/object-type";

import template from './top-articles.html';
import moment from 'moment';

import resources from '../../../components/api/resources/resources';
import userPermissions from '../../../components/session/local-user-permissions';
import columnSelector from './column-selector-component';
import _ from "lodash";
import screenshottingTemplate from "./top-articles-screenshotting.html";
import {ApiConfig} from "../../../components/api/api";

const MODULE_NAME = 'top-articles-table';

angular.module(MODULE_NAME, [resources, userPermissions, columnSelector])

  .component('adnTopArticlesTable', {
    template: template,
    controllerAs: 'ctrl',
    bindings: {
      object: '<',
      children: '<',
      parentType: '@',
      dateSelectorId: '@'
    },
    controller: function($scope, $rootScope, adnShotter, $uibModal, $log, $location, $timeout, localUserProfile, statsResource, ChartOptionsUtil, LocalNetworkProfile) {
      const ctrl = this;
      ctrl.sel = {};

      ctrl.sorting = {
        orderByParam: 'impressions',
        asc: true
      };

      ctrl.isLocalhost = ($location.host() === 'localhost');

      ctrl.$onInit = function() {
        let chartOptionObject = OBJECT_TYPE[ctrl.parentType];
        let params = {
          id: ctrl.object.id,
          idKey: chartOptionObject.apiParam + 'Id',
        };

        function fetchShots() {
          ctrl.screenshotting = true;
          adnShotter.fetchScreenshots(ctrl.object.id).then(function(data) {
            ctrl.screenshots = data;
            _.forEach(ctrl.screenshots, function(s) {
              s.articleUrlHttps = (s.articleUrl || "").indexOf("http") === 0 ? s.articleUrl : "https://" + s.articleUrl;
            });
          }).finally(function() {
            ctrl.screenshotting = false;
          });
        }
        fetchShots();

        ctrl.refresh = function() {
          fetchShots();
        };

        ctrl.deleteScreenshot = function(screenshot, index) {
          ctrl.deleting = true;
          adnShotter.deleteScreenshot(screenshot).then(function() {
            _.pullAt(ctrl.screenshots, index);
          }).finally(function() {
            ctrl.deleting = false;
          });
        };

        function checkTargeting() {
          ctrl.targetingUrls = {};
          const safeUrls = _.get(ctrl.object, ['targeting', 'articleTarget', 'urls'], []);
          _.forEach(safeUrls, function(url) {
            ctrl.targetingUrls[url] = ctrl.object.targeting.articleTarget.negated ? 'EXCLUDED' : 'INCLUDED';
          });
          ctrl.targetingUrlsOpen = false;
          if (safeUrls.length > 0) {
            ctrl.targetingUrlsOpen = ctrl.object.targeting.articleTarget.negated ? 'EXCLUDED' : 'INCLUDED';
          }
        }
        checkTargeting();

        function saveTargeting() {
          ctrl.isSaving = true;
          ctrl.object.savePartial(['targeting']).then(function(savedLineItem) {
            checkTargeting();
            ctrl.isSaving = false;
            $rootScope.$emit('lineItemTargetingUpdate', savedLineItem);
          });
        }

        ctrl.clearAllTargeting = function() {
          ctrl.object.targeting.articleTarget.negated = false;
          ctrl.object.targeting.articleTarget.urls = [];
          saveTargeting();
        };

        ctrl.resetTargeting = function() {
          checkTargeting();
        };

        ctrl.targetArticle = function(url) {
          if (ctrl.object.targeting.articleTarget.urls.length === 0) {
            ctrl.object.targeting.articleTarget.negated = false;
          }
          ctrl.object.targeting.articleTarget.urls.push(url);
          saveTargeting();
        };

        ctrl.excludeArticle = function(url) {
          if (ctrl.object.targeting.articleTarget.urls.length === 0) {
            ctrl.object.targeting.articleTarget.negated = true;
          }
          ctrl.object.targeting.articleTarget.urls.push(url);
          saveTargeting();
        };

        ctrl.screenshottingOptions = function(articleUrl) {
          const modalInstance = $uibModal.open({
            template: screenshottingTemplate,
            controllerAs: 'modalCtrl',
            windowClass: '',
            size: 'lg',
            controller: function() {
              const modalCtrl = this;

              modalCtrl.articleUrl = articleUrl;
              modalCtrl.specialLineItemId = 'line-item-id-for-screenshotter';
              modalCtrl.device = 'DESKTOP';

              const theChildren = [];
              modalCtrl.creatives = [];
              modalCtrl.creatives.push({
                name: 'Most suitable from line item',
                id: modalCtrl.specialLineItemId
              });
              _.forEach(ctrl.children, function(c) {
                if (c.objectState === 'ACTIVE') {
                  modalCtrl.creatives.push({
                    name: c.name,
                    width: c.width,
                    height: c.height,
                    id: c.id,
                  });
                }
              });
              modalCtrl.theCreativeId = modalCtrl.creatives[0].id;

              modalCtrl.change = function() {
                _.forEach(ctrl.children, function(c) {
                  if (c.objectState === 'ACTIVE' && (modalCtrl.theCreativeId === modalCtrl.specialLineItemId || c.id === modalCtrl.theCreativeId)) {
                    theChildren.push({
                      cId: c.id,
                      w: c.width,
                      h: c.height
                    });
                  }
                });
                const env = ApiConfig.obtain().getId().replace("_adn", "");
                modalCtrl.theScript = "var scriptEl = document.createElement(\"script\");scriptEl.src=(\"https://cdn.adnuntius.com/shotter.js?env=" + env + "&cjson=" + encodeURIComponent(JSON.stringify(theChildren)) + "&networkId=" + LocalNetworkProfile.getNetworkTag() + "\");document.body.appendChild(scriptEl);";
              };
              modalCtrl.change();

              modalCtrl.triggered = false;
              modalCtrl.triggerScreenshot = function() {
                const creativeId = modalCtrl.theCreativeId === modalCtrl.specialLineItemId ? null : modalCtrl.theCreativeId;
                const lineItemId = modalCtrl.theCreativeId === modalCtrl.specialLineItemId ? ctrl.object.id : null;

                adnShotter.takeShot(creativeId, lineItemId, articleUrl, modalCtrl.device);
                modalCtrl.triggered = true;


                $timeout(function() {
                  fetchShots();
                  ctrl.showScreenshots = true;
                }, 250);
              };
            }
          });
          modalInstance.closed.then(function() {
          });
        };

        ctrl.removeFromTargeting = function(url) {
          ctrl.object.targeting.articleTarget.urls = _.filter(ctrl.object.targeting.articleTarget.urls, function(targetUrl) {
            return targetUrl !== url;
          });
          saveTargeting();
        };

        ctrl.sortUpdate = function(field) {
          if (field.key === ctrl.sorting.orderByParam) {
            ctrl.sorting.asc = !ctrl.sorting.asc;
            return;
          }
          ctrl.sorting.orderByParam = field.key;
          ctrl.sorting.asc = true;
        };

        let chartOptions = ChartOptionsUtil.getOptions(chartOptionObject.caps);
        ctrl.parentParam = chartOptionObject.apiParam;

        ctrl.defaultTopN = 50;
        ctrl.topN = ctrl.defaultTopN || 50;
        function callForStats() {
          params.topN = ctrl.topN;
          ctrl.loaded = false;
          statsResource.getArticles(params, function (data) {
            ctrl.articles = data.chunks;
            const allOthersIndex = _.findIndex(data.chunks, function(row) {
              return row.article === 'all-others-stats-group-id';
            });

            ctrl.totals = data.totals;
            ctrl.totalCount = data.totalCount;

            ctrl.otherRow = allOthersIndex > -1 ? _.pullAt(data.chunks, allOthersIndex)[0] : null;
            if (ctrl.otherRow) {
              const remainingObjects = data.totalCount - params.topN;
              ctrl.otherRow.updatedName = remainingObjects + " remaining articles";
            }
            ctrl.articles.forEach(function(article) {
              if (article.matchScore) {
                if (article.matchScore === 'SLIGHTLY_RELATED') {
                  article.matchScore = 1;
                }
                if (article.matchScore === 'SOMEWHAT_RELATED') {
                  article.matchScore = 2;
                }
                if (article.matchScore === 'MODERATELY_RELATED') {
                  article.matchScore = 3;
                }
                if (article.matchScore === 'CLOSELY_RELATED') {
                  article.matchScore = 4;
                }
                if (article.matchScore === 'VERY_CLOSELY_RELATED') {
                  article.matchScore = 5;
                }
              }
            });
            ctrl.loaded = true;
          }, function (err) {
            $log.warn(err);
            ctrl.error = true;
          }, $scope);
        }

        ctrl.startDate = 'UNDEFINED';
        const articleListener = $rootScope.$on('chartDates' + chartOptions.idKey + (ctrl.dateSelectorId + ''), function(event, data) {
          if (ctrl.startDate !== 'UNDEFINED' && moment(ctrl.startDate).isSame(data.startDate) && moment(ctrl.endDate).isSame(data.endDate)) {
            return;
          }

          ctrl.startDate = data.startDate;
          ctrl.endDate = data.endDate;

          ctrl.articles = null;
          ctrl.error = false;

          if (ctrl.startDate && ctrl.endDate) {
            params.startDate = ctrl.startDate;
            params.endDate = ctrl.endDate;
          }

          params.aggregateTo = 'article';
          params.groupBy = null;

          callForStats();
        });

        ctrl.topNHook = {
          newN: function(newN) {
            ctrl.topN = newN;
            callForStats();
          }
        };

        $scope.$on('$destroy', function() {
          articleListener();
        });
      };
    }
  });

export default MODULE_NAME;