/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import titleTemplate from '../default-title-template.html';
import supportTemplate from './support.html';
import versionCopyTemplate from './version-copy.html';
import versionHistoryTemplate from './version-history.html';
import shotterTemplate from './shotter.html';
import creativeCheckerTemplate from './creative-checker.html';
import scanResultsTemplate from './scan-results.html';
import supportPagesTemplate from './support-pages.html';
import tcfTemplate from './tcf.html';
import resources from '../../../components/api/resources/resources';
import releaseNotesEditTemplate from './release-notes-edit.html';
import releaseNotesListTemplate from './release-notes-list.html';

import versionHistoryController from './version-history-controller';
import creativeCheckerController from './creative-checker-controller';
import shotterController from './shotter-controller';
import scanResultsController from './scan-results-controller';
import releaseNotesController from './release-notes-controller';
import versionCopyController from './version-copy-controller';
import {Uuid} from "../../../components/util/uuid";

const MODULE_NAME = "simple-module";

angular.module(MODULE_NAME, [uiRouter, resources, shotterController, resources, versionHistoryController, versionCopyController, creativeCheckerController, releaseNotesController, scanResultsController])

  .config(function($stateProvider) {
    $stateProvider.state('app.simple', {
      abstract: true
    }).state('app.simple.supporting-pages', {
      url: '/supporting-pages',
      views: {
        'content@app': {
          template: supportPagesTemplate,
          controller: function() {},
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Supporting Pages'
      }
    }).state('app.simple.tcf', {
      url: '/tcf',
      views: {
        'content@app': {
          template: tcfTemplate,
          controller: function(TCF) {
            const ctrl = this;
            ctrl.gdpr = 'GDPR_ON';

            ctrl.findPurposes = function() {
              ctrl.doing = true;
              TCF.checkTcf(ctrl.gdpr === 'GDPR_AD_UNIT' && ctrl.auTagId ? ctrl.auTagId : (ctrl.gdpr || 0), ctrl.tcf).then(function(data) {
                ctrl.gdprResults = data;
                ctrl.doing = false;
              });
            };
          },
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'TCF Purposes Enabled By Adnuntius'
      }
    }).state('app.simple.scan-results', {
      url: '/scan-results',
      views: {
        'content@app': {
          template: scanResultsTemplate,
          controller: 'ScanResultsCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Scan Results'
      }
    }).state('app.simple.shotter', {
      url: '/shotter?creativeId',
      views: {
        'content@app': {
          template: shotterTemplate,
          controller: 'ShotterCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Screenshotter'
      }
    }).state('app.simple.creative-check', {
      url: '/creative-check?creativeId',
      views: {
        'content@app': {
          template: creativeCheckerTemplate,
          controller: 'CreativeCheckerCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Creative Checker'
      }
    }).state('app.simple.release-notes', {
      url: '/release-notes-list',
      views: {
        'content@app': {
          template: releaseNotesListTemplate,
          controller: 'ReleaseNotesCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      resolve: {
        model: /*@ngInject*/ function(releaseNotesHelper) {
          return releaseNotesHelper.query(null, true);
        }
      },
      data: {
        title: 'Admin List of Release Notes'
      }
    }).state('app.simple.release-notes.release-note', {
      url: '/release-note/:id',
      views: {
        'content@app': {
          template: releaseNotesEditTemplate,
          controller: 'ReleaseNotesCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: `
            <div class="breadcrumbContainer">
                <a ui-sref="app.simple.release-notes">Back to release notes list</a>
            </div>
            <h1 adn-state-title></h1>
          `
        }
      },
      resolve: {
        model: /*@ngInject*/ function($stateParams, releaseNotesHelper) {
          if ($stateParams.id === 'new') {
            return {
              id: Uuid.generate(),
              contentType: 'HTML',
              status: 'DRAFT',
              state: 'ACTIVE',
              apps: ['MARKETPLACE', 'ADSERVER']
            };
          }
          return releaseNotesHelper.getFromId($stateParams.id);
        }
      },
      data: {
        title: 'Edit Release Note'
      }
    }).state('app.simple.version-history', {
      url: '/version-history',
      views: {
        'content@app': {
          template: versionHistoryTemplate,
          controller: 'VersionHistoryCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Version History'
      }
    }).state('app.simple.version-copy', {
      url: '/version-copy',
      views: {
        'content@app': {
          template: versionCopyTemplate,
          controller: 'VersionCopyCtrl',
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Version Copy'
      }
    }).state('app.simple.support', {
      url: '/support',
      views: {
        'content@app': {
          template: supportTemplate,
          controller: function() {
          },
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        title: 'Support Stuff'
      }
    }).state('app.simple.test-style', {
      url: '/test-style',
      views: {
        'content@app': {
          template: `<p>Just a public page to show what the custom design looks like.</p>`,
          controller: function() {
          },
          controllerAs: 'ctrl'
        },
        'title@app': {
          template: titleTemplate
        }
      },
      data: {
        public: true,
        title: 'Testing the Style of the Custom UI'
      }
    });
  });

export default MODULE_NAME;