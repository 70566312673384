/**
 * Copyright © 2025 Adnuntius AS.
 */
import {LocalStorage} from "./local-storage";
import * as _ from "lodash";

type SiteCountry = {
  country: string;
  count: number;
};

type CustomEvent = {
  id: string;
  name: string;
  type: string;
  priority: number;
};

type Team = {
  id: string;
  data: Record<string, string>;
  country: string;
};

type NetworkInfo = {
  axProductsCount: number;
  axProductsWithReviewCount: number;
  lineItemTemplatesCount: number;
  externalDemandSourceCount: number;
  teamGroupsCount: number;
  teamsCount: number;
  creativeSetCount: number;
  firstPartyAudienceCount: number;
  idsOfFullPageLayouts: number;
  teams: Array<Team>;
  contextServiceConfigurationCount: number;
  folioCount: number;
  userCount: number;
  scanEnabledSites: number;
  siteCountries: Array<SiteCountry>;
  customEvents: Array<CustomEvent>;
};

type SupplementaryNetworkInfo = {
  themeId: string;
};

export class LocalNetworkInfo {
  private static instance: LocalNetworkInfo;
  private static readonly NETWORK_INFO_KEY = 'networkInfo';
  private static readonly SUPP_NETWORK_INFO_KEY = 'networkInfoSupp';

  info: NetworkInfo;
  suppInfo: SupplementaryNetworkInfo;

  private constructor() {
  }

  public static obtain(): LocalNetworkInfo {
    if (!this.instance) {
      this.instance = new LocalNetworkInfo();
    }
    this.instance.refresh();
    return this.instance;
  }

  private save() {
    LocalStorage.obtain().setItem(LocalNetworkInfo.NETWORK_INFO_KEY, this.info);
    LocalStorage.obtain().setItem(LocalNetworkInfo.SUPP_NETWORK_INFO_KEY, this.suppInfo);
  }

  private refresh() {
    const localStorageInfo = LocalStorage.obtain().getItem(LocalNetworkInfo.NETWORK_INFO_KEY);
    this.info = _.isEmpty(localStorageInfo) ? _.isEmpty(this.info) ? {} : this.info : localStorageInfo;
    this.reorderCustomEvents();
    const localStorageSuppInfo = LocalStorage.obtain().getItem(LocalNetworkInfo.SUPP_NETWORK_INFO_KEY);
    this.suppInfo = _.isEmpty(localStorageSuppInfo) ? _.isEmpty(this.suppInfo) ? {} : this.suppInfo : localStorageSuppInfo;
  }

  increment(key: string) {
    LocalNetworkInfo.instance.refresh();
    this.info[key] = this.info[key] || 0;
    this.info[key]++;
    LocalNetworkInfo.instance.save();
  }

  setInfo(resp: NetworkInfo) {
    LocalNetworkInfo.instance.refresh();
    this.info = resp;
    LocalNetworkInfo.instance.save();
  }

  getInfo(key: string) {
    return this.info[key];
  }

  getSuppInfo(key: string) {
    return this.suppInfo[key];
  }

  setSuppInfo(key: string, val: any) {
    LocalNetworkInfo.instance.refresh();
    this.suppInfo[key] = val;
    LocalNetworkInfo.instance.save();
  }

  addCustomEvent(customEvent: CustomEvent) {
    this.info.customEvents = this.info.customEvents || [];
    this.info.customEvents = this.info.customEvents.filter((ce) => {
      return ce.id !== customEvent.id;
    });
    this.info.customEvents.push({
      id: customEvent.id,
      name: customEvent.name,
      type: customEvent.type,
      priority: customEvent.priority
    });
    this.reorderCustomEvents();
    this.save();
  }

  reorderCustomEvents(){
    this.info.customEvents = this.info.customEvents || [];
    this.info.customEvents.sort((a1, a2) => {
      const a1Priority = a1.priority || 0;
      const a2Priority = a2.priority || 0;
      if (a1Priority > a2Priority) {
        return -1;
      }
      if (a1Priority < a2Priority) {
        return 1;
      }
      return 0;
    });
  }

  getTeamCountriesSet(): Array<string> {
    return _.uniq(_.map(this.info.teams, function (t) {
      return t.country;
    }));
  }

  showSiteCountryTargeting(): boolean {
    const totalSiteCountries = _.get(this.info, 'siteCountries', []).length;
    const siteCountries = _.filter(this.info.siteCountries, function (sc) {
      return sc.country && (sc.count > 1 || totalSiteCountries > 2);
    });
    return siteCountries.length > 1;
  }
}
